import { API_URL } from './constants';

export const getSessionId = () => localStorage.getItem('sessionId');

export const isConnected = () => {
  const item = getSessionId();
  return item && item != null && item !== 'undefined';
};

export const getApiURL = () => {
  const host = window.location.host.split('.')[0];
  if (host === 'app') {
    return API_URL;
  } else return API_URL;
};

export const isNumber = (nb) => typeof nb === 'number';

export const isLocalhost = () =>
  window && window.location && window.location.hostname === 'localhost';
