export const API_DEV_URL = 'https://api-dev.karmalinks.io';
export const API_URL = 'https://api.karmalinks.io';

export const EMAIL_DOMAIN_STATUS = {
  COMPANY: 'COMPANY',
};

export const LOCAL_STORAGE = {
  CURRENT_DOMAIN_VALIDATION: 'CURRENT_DOMAIN_VALIDATION',
};

export const CRISP_WEBSITE_ID_PERS = 'bea2794c-105c-469d-9a50-07179561f09e';

export const BACKLINK_REQUEST_STATUS = {
  PENDING: 'PENDING',
  LIVE: 'LIVE',
  WARNING: 'WARNING',
  LOST: 'LOST',
  EXPIRED: 'EXPIRED',
};
