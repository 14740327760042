import {
  CheckCircleFilled,
  LinkedinFilled,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Form,
  Input,
  message,
} from 'antd';
import {
  useContext,
  useState,
} from 'react';
import ProfileContext from 'services/profileContext';
import styles from 'styles/components/onboarding.module.scss';
import { submitLinkedInUrl } from '../../services/api/users';

const VerifyLinkedin = ({ nextStep, previousStep }) => {
  const { profile } = useContext(ProfileContext);
  const [useAlternativeMethod, setUseAlternativeMethod] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitURL = async (values) => {
    setLoading(true);
    try {
      await submitLinkedInUrl(values.url);
      window.location.reload();
    } catch (error) {
      message.error('Error while submitting URL, please double check the URL.');
    }
    setLoading(false);
  };
  return (
    <div>
      <h1 className={styles.title}>Verify your identity</h1>
      {!profile.linkedinProfile ? (
        <>
          <p>
            To ensure the platform accuracy and quality, please verify your
            identity
          </p>
          <Alert
            type="info"
            showIcon
            message="We won't use or store any information from your Linkedin profile, the
         purpose of the Linkedin Connect is to verify you are a real person."
          />
        </>
      ) : (
        <></>
      )}
      <div className={styles.linkedinButtonWrapper}>
        <div className={styles.linkedinButtonInner}>
          {!profile.linkedinProfile ? (
            <div>
              {useAlternativeMethod ? (
                <Form
                  layout="inline"
                  style={{ justifyContent: 'center' }}
                  onFinish={submitURL}
                >
                  <Form.Item
                    label={'Enter your LinkedIn profile URL'}
                    name="url"
                    layout="vertical"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your LinkedIn profile URL',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          } else if (!value.startsWith('https://www.linkedin.com/in/')) {
                            return Promise.reject(
                              new Error('The URL should start with https://www.linkedin.com/in/')
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      size={'large'}
                      style={{ minWidth: 320 }}
                      placeholder="https://www.linkedin.com/in/xxxxxxxx"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size={'large'}
                      type="primary"
                      loading={loading}
                      htmlType={'submit'}
                      style={{
                        marginTop: 28,
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <div>
                    <a href={profile.ui.liOAuthUrl}>
                      <Button
                        className={styles.linkedinButton}
                        type="primary"
                        size="large"
                      >
                        <LinkedinFilled style={{ fontSize: 22 }}/>
                        Sign in with LinkedIn
                      </Button>
                    </a>
                  </div>
                  <div style={{
                    textAlign: 'center',
                    marginTop: 12,
                  }}>
                    {'Not working? '}
                    <span onClick={() => setUseAlternativeMethod(true)} style={{
                      color: '#007ab6',
                      cursor: 'pointer',
                    }}>
                  Click here instead.
                </span>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div>
              <div>
                <div className={styles.verifyIcon}>
                  <CheckCircleFilled/>
                </div>
              </div>
              <h2 style={{ textAlign: 'center' }}>
                Your account has been verified with your LinkedIn profile
              </h2>
            </div>
          )}
        </div>
      </div>

      {profile.linkedinProfile && (
        <div className={styles.cta}>
          <Button
            type="primary"
            size="large"
            onClick={nextStep}
            className={styles.nextButton}
          >
            Next step
          </Button>
        </div>
      )}
    </div>
  );
};

export default VerifyLinkedin;
