import React from 'react';
import { Spin } from 'antd';
import 'styles/components/loader.module.scss';
import { LoadingOutlined } from '@ant-design/icons';

function Loader({ tip }) {
  return (
    <div className="FullScreenLoader">
      <Spin tip={tip} size="large" indicator={<LoadingOutlined spin />} />
      {tip && <div style={{ color: '#858585', marginTop: 24 }}>{tip}</div>}
    </div>
  );
}

export default Loader;
