/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleFilled,
  DownloadOutlined,
  FileDoneOutlined,
  HourglassOutlined,
  HourglassTwoTone,
  LoadingOutlined,
  PauseCircleOutlined,
  PlusCircleOutlined,
  SendOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Alert, Button, message, Table, Tabs, Tag, Typography } from 'antd';
import DomainSettingsModal from 'components/domainSettingsModal';
import Header from 'components/header';
import Onboarding from 'components/onboarding/index.js';
import SentBacklinksList from 'components/sentBaclinksList';
import { useContext, useEffect, useState } from 'react';
import { getDetails } from 'services/api/domains';
import DomainContext from 'services/domainContext';
import ProfileContext from 'services/profileContext';
import styles from 'styles/dashboard.module.scss';
import { LOCAL_STORAGE } from 'utils/constants';
const { Text } = Typography;

const Vignette = ({ name, figure, featured = false }) => (
  <div
    className={`${styles.vignetteWrapper}${
      featured ? ` ${styles.vignetteFeatured}` : ''
    }`}
  >
    <div className={styles.vignetteFigure}>{figure}</div>
    <div className={styles.vignetteName}>{name}</div>
  </div>
);

const ReceivedBacklinks = ({ received }) => {
  const dataSource = received;

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Domain Rating',
      dataIndex: 'dr',
      key: 'dr',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  return (
    <div>
      <Table
        locale={{ emptyText: 'No Backlinks yet' }}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

const Dashboard = () => {
  const [forceOnboarding, setForceOnboarding] = useState(false);
  const [newDomainFlow, setNewDomainFlow] = useState(false);
  const { profile } = useContext(ProfileContext);
  const { domains } = useContext(DomainContext);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [domainDetailsLoading, setDomainDetailsLoading] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const [settingsModalisOpen, setSettingsModalisOpen] = useState(false);
  const [hasBacklinkTodo, setHasBacklinkTodo] = useState(false);

  const getDomainDetails = () => {
    setDomainDetailsLoading(true);
    getDetails({ name: selectedDomain.name })
      .then(({ data }) => {
        setDomainDetails(data);
        const hasBacklinkTodoVar =
          domainDetails?.backlinks?.sent.filter((s) => s.status === 'TODO')
            ?.length > 0;
        setHasBacklinkTodo(hasBacklinkTodoVar);
        const currentDomainNotSetup =
          data && data.status === 'TO_REVIEW' && !data.verified;

        const isNotTheFirstDomain = domains?.length > 1;

        if (currentDomainNotSetup && isNotTheFirstDomain) {
          localStorage.setItem(
            LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION,
            data.name
          );
          setNewDomainFlow(true);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while loading domain details');
      })
      .finally(() => {
        setDomainDetailsLoading(false);
      });
  };

  const openNewDomainFlow = () => {
    setNewDomainFlow(false);
    localStorage.removeItem(LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION);
    setTimeout(() => {
      //reset localstorage
      setNewDomainFlow(true);
    });
  };

  useEffect(() => {
    const profileNotValidated =
      !profile.emailVerified || !profile.linkedinProfile;
    const noDomain =
      !domains ||
      domains.length === 0 ||
      domains.filter((d) => d.status !== 'TO_REVIEW')?.length === 0;
    setForceOnboarding(profileNotValidated || noDomain);
    setSelectedDomain(domains[0]);
  }, []);

  useEffect(() => {
    if (selectedDomain && selectedDomain.name) getDomainDetails();
  }, [selectedDomain]);

  const openSettingsModal = () => setSettingsModalisOpen(true);
  const closeSettingsModal = () => setSettingsModalisOpen(false);

  const getDomainStatusIcon = (domain, status) => {
    if (!domain.live) {
      return <PauseCircleOutlined style={{ marginRight: 4 }} />;
    }
    switch (status) {
      default:
      case 'TO_REVIEW':
        return (
          <HourglassOutlined
            twoToneColor="#051200"
            className={styles.iconStatus}
          />
        );
      case 'APPROVED':
        return <CheckCircleFilled className={styles.iconStatus} />;
    }
  };

  const sentBacklinksPending = domainDetails?.backlinks?.sent.filter(
    (e) => e.status === 'PENDING'
  ).length;

  const hasAtLeastOneDomainLive =
    domains?.filter((d) => d.live && d.status === 'APPROVED').length > 0;

  return (
    <div>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          {hasAtLeastOneDomainLive && (
            <div>
              <Alert
                style={{ border: 'none' }}
                className={styles.alertNextRound}
                description={
                  <div className={styles.nextRoundWrapper}>
                    <div className={styles.nextRoundPicture}>
                      <img
                        src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ten O’Clock.png"
                        alt="Ten O’Clock"
                        width="65"
                        height="65"
                      />
                    </div>
                    <div className={styles.nextRoundContent}>
                      <div className={styles.nextRoundTitle}>
                        Waiting for next matching round
                      </div>
                      <div className={styles.nextRoundSubitle}>
                        Matching rounds happen on Mondays 10am EST.
                      </div>
                    </div>
                  </div>
                }
                type="info"
              />
            </div>
          )}
          <div className={styles.domainsTitle}>
            {`Domains${
              domains && domains.length > 0 ? ` (${domains.length})` : ''
            }`}
          </div>
          <div className={styles.domainsListWrapper}>
            {domains.map((d) => (
              <Tag
                onClick={() => {
                  setSelectedDomain(d);
                }}
                color={d.status === 'TO_REVIEW' ? '' : 'green'}
                className={`${styles.domainTag} ${
                  selectedDomain?._id === d._id ? styles.selectedTag : ''
                }${d.status === 'TO_REVIEW' ? ` ${styles.domainNotLive}` : ''}`}
                key={d._id}
              >
                {selectedDomain?._id === d._id && domainDetailsLoading ? (
                  <LoadingOutlined spin style={{ marginRight: 4 }} />
                ) : (
                  getDomainStatusIcon(d, d.status)
                )}
                {d.name}
              </Tag>
            ))}
            <Tag
              color="blue"
              className={`${styles.domainTag} ${styles.domainTagAdd}`}
              key={'add'}
              onClick={openNewDomainFlow}
            >
              <PlusCircleOutlined />
              &nbsp;Add a domain
            </Tag>
          </div>
          {domainDetails && (
            <div className={styles.detailsWrapper}>
              {/* {domainDetailsLoading && (
                <div className={styles.loaderWrapper}>
                  <Loader />
                </div>
              )} */}
              {domainDetails.dr < 5 ? (
                <div>
                  <Alert
                    message="Domain Rating too low"
                    description={
                      <div>
                        Your domain rating is too low to participate (lower than
                        5).
                        <br />
                        <br />
                        The good news is that we have a solution for you!
                        <br />
                        <br />
                        <b>
                          Use Uneed Directories to automatically grow your DR
                          above 5 and start using KarmaLinks
                        </b>
                        <div>
                          <a
                            href="https://uneed-directories.com/?ref=karmalinks"
                            target={'_blank'}
                            rel="noreferrer"
                          >
                            <Button
                              size="large"
                              type="primary"
                              style={{ marginTop: 14 }}
                            >
                              Go to Uneed Directories
                            </Button>
                          </a>
                        </div>
                      </div>
                    }
                    type="warning"
                    showIcon
                  />
                </div>
              ) : (
                <>
                  {!domainDetails.live && (
                    <Alert
                      message="Domain is paused"
                      description={
                        <div>
                          Update domain's settings to set domain to Live again
                          and start collecting backlinks.
                        </div>
                      }
                      type="warning"
                      icon={<PauseCircleOutlined />}
                      showIcon
                      style={{ border: 'none', marginBottom: 12 }}
                    />
                  )}
                  {domainDetails.language !== 'en' && (
                    <Alert
                      message="About your domain's language"
                      description={
                        <div>
                          The language{' '}
                          <Text code>{domainDetails.language}</Text> is
                          supported but <b>there are not a lot of domains using
                          this language on the platform.</b> You may not receive as
                          relevant backlinks as we aim to. Sorry for that! This
                          is improving every day.
                        </div>
                      }
                      type="info"
                      showIcon
                      style={{ border: 'none', marginBottom: 12 }}
                    />
                  )}
                  {hasBacklinkTodo && (
                    <Alert
                      style={{ border: 'none', marginBottom: 12 }}
                      message="Backlinks to publish"
                      description={
                        <div>
                          {domainDetails.backlinks.sent
                            .filter((s) => s.status === 'TODO')
                            .map((ba) => (
                              <div
                                key={ba._id}
                              >{`Add a backlink poiting to ${ba.name}.`}</div>
                            ))}
                        </div>
                      }
                      type="info"
                      icon={<FileDoneOutlined />}
                      showIcon
                    />
                  )}
                  <div className={styles.settingsWrapper}>
                    <Button
                      type="link"
                      onClick={openSettingsModal}
                      icon={<SettingOutlined />}
                    >
                      {`Settings for ${domainDetails.name}`}
                    </Button>
                  </div>

                  {domainDetails.status === 'TO_REVIEW' && (
                    <div className={styles.inReviewWrapper}>
                      <div className={styles.inReviewInner}>
                        <HourglassTwoTone
                          twoToneColor={'#564e99'}
                          className={styles.inReviewIcon}
                        />
                        {domainDetails.verified ? (
                          <h2>We're currently reviewing this domain</h2>
                        ) : (
                          <h2>This domain not yet verified</h2>
                        )}
                      </div>
                    </div>
                  )}
                  {domainDetails.status === 'APPROVED' && (
                    <div>
                      <div className={styles.vignetteList}>
                        <Vignette
                          figure={domainDetails.points}
                          name={'Karma Points'}
                          featured
                        />
                        <Vignette
                          figure={domainDetails.dr}
                          name={'Domain Rating'}
                        />
                        <Vignette
                          figure={domainDetails?.backlinks?.received.length}
                          name={'Received Backlinks'}
                        />
                      </div>
                      <div>
                        <h2>History of requests and backlinks</h2>
                        <Tabs
                          defaultActiveKey="received"
                          items={[
                            {
                              key: 'received',
                              label: `Received Backlinks`,
                              children: (
                                <ReceivedBacklinks
                                  received={domainDetails.backlinks.received}
                                />
                              ),
                              icon: <DownloadOutlined />,
                            },
                            {
                              key: 'sent',
                              label: `Sent Backlinks ${
                                sentBacklinksPending > 0
                                  ? ` (${sentBacklinksPending} pending)`
                                  : ''
                              }`,
                              children: (
                                <SentBacklinksList
                                  domain={domainDetails}
                                  getDomainDetails={getDomainDetails}
                                />
                              ),
                              icon: <SendOutlined />,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {(forceOnboarding || newDomainFlow) && (
        <Onboarding
          firstOnboarding={forceOnboarding}
          close={
            newDomainFlow
              ? () => {
                  localStorage.removeItem(
                    LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION
                  );
                  setNewDomainFlow(false);
                }
              : () => {}
          }
        />
      )}
      {domainDetails && (
        <DomainSettingsModal
          isOpen={settingsModalisOpen}
          onClose={closeSettingsModal}
          domain={domainDetails}
          getDomainDetails={getDomainDetails}
        />
      )}
    </div>
  );
};

export default Dashboard;
