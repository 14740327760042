import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from 'services/api/users';
import styles from 'styles/login.module.scss';

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { resetPasswordToken } = useParams();

  const onFinish = (values) => {
    setLoading(true);
    resetPassword({
      password: values.password,
      resetPasswordToken: resetPasswordToken,
    })
      .then(() => {
        message.success(
          'Password successfully reset, redirecting to login...',
          2
        );
        setTimeout(() => {
          // go to verify-email
          navigate(`/login`);
        }, 2200);
      })
      .catch((e) => {
        console.error(e);
        message.error('Error when resetting password, please try again');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.vignetteWrapper}>
          <div className={styles.vignetteInner}>
            <div>
              <img
                src="/img/logo.png"
                width={130}
                height={130}
                alt="Karmalinks logo"
                className={styles.logo}
              />
            </div>
            <h1>Reset your password to KarmaLinks</h1>
            <div>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="Enter a new Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        } else if (value.length < 8) {
                          return Promise.reject(
                            new Error(
                              'The password must contain more than 8 caracters'
                            )
                          );
                        } else if (!/[A-Z]+/.test(value)) {
                          return Promise.reject(
                            new Error(
                              'The password must contain at least 1 capital letter'
                            )
                          );
                        } else if (!/[a-z]/.test(value)) {
                          return Promise.reject(
                            new Error(
                              'The password must contain at least 1 lowercase letter'
                            )
                          );
                        } else if (!/\d/.test(value)) {
                          return Promise.reject(
                            new Error(
                              'The password must contain at least 1 number'
                            )
                          );
                        } else if (!/[^\w\s]/.test(value)) {
                          return Promise.reject(
                            new Error(
                              'The password must contain at least 1 symbol'
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    prefix={<LockOutlined />}
                    placeholder="Enter a new password"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm the new password"
                  name="confirm"
                  rules={[
                    {
                      required: true,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'The new password that you entered do not match!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    prefix={<LockOutlined />}
                    placeholder="Confirm the new password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size={'large'}
                    type="primary"
                    block
                    loading={loading}
                    htmlType={'submit'}
                  >
                    Save this new password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
