import { getApiURL, getSessionId } from 'utils/utils';
import axios from 'axios';
import { API_URL, LOCAL_STORAGE } from 'utils/constants';

const instance = axios.create({
  // baseURL: 'http://localhost:9781',
  baseURL: getApiURL(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
export const getProfile = () =>
  instance.post(`${API_URL}/web-app/users/get-profile`, undefined, {
    headers: {
      sessionId: getSessionId(),
    },
  });
export const login = (params) =>
  instance
    .post(`${API_URL}/web-app/users/login`, params)
    .then((response) =>
      localStorage.setItem('sessionId', response.data.sessionId)
    );

export const forgotPassword = (params) =>
  instance.post(`${API_URL}/web-app/users/forget-password`, params);
export const resetPassword = (params) =>
  instance.post(`${API_URL}/web-app/users/reset-password`, params);

export const register = (params) =>
  instance.post(`${API_URL}/web-app/users/register`, params);

export const verifyEmail = (params) =>
  instance.post(`${API_URL}/web-app/users/verify-email`, params);

export const askCode = (params) =>
  instance.post(`${API_URL}/web-app/users/ask-code`, params);

export const logout = (redirectHome) =>
  instance
    .post(`${API_URL}/web-app/users/logout`, undefined, {
      headers: {
        sessionId: getSessionId(),
      },
    })
    .then(() => {
      localStorage.removeItem('sessionId');
      localStorage.removeItem(LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION);
      if (redirectHome) {
        redirectHome();
      }
    })
    .catch(() => {
      localStorage.removeItem('sessionId');
      localStorage.removeItem(LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION);
      if (redirectHome) {
        redirectHome();
      }
    });

export const submitLinkedInUrl = (url) =>
  instance.post(`${API_URL}/web-app/users/submit-linkedin-url`, { linkedInUrl: url }, {
    headers: {
      sessionId: getSessionId(),
    },
  });
