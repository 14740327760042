/* eslint-disable react-hooks/exhaustive-deps */
import { UserOutlined } from '@ant-design/icons';
import { Button, Input, Form, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from 'services/api/users';
import styles from 'styles/login.module.scss';
import { isConnected } from 'utils/utils';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isConnected()) {
      navigate('/dashboard');
    }
  }, []);
  const onFinish = (values) => {
    setLoading(true);
    forgotPassword({ email: values.email })
      .then(() => {
        message.success(
          'You received an email, click on the link to reset your password, Redirecting...',
          3
        );
        setTimeout(() => {
          navigate('/login');
        }, 3500);
      })
      .catch((e) => {
        console.error(e);
        message.error(
          'Error while resetting your password, please try again or contact support'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.vignetteWrapper}>
          <div className={styles.vignetteInner}>
            <div>
              <img
                src="/img/logo.png"
                width={130}
                height={130}
                alt="Karmalinks logo"
                className={styles.logo}
              />
            </div>
            <h1>Forgot password</h1>
            <div>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="Email"
                  name="email"
                  placeholder="Enter your email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                  ]}
                >
                  <Input
                    size={'large'}
                    prefix={<UserOutlined />}
                    placeholder="Enter your email"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    size={'large'}
                    type="primary"
                    block
                    htmlType={'submit'}
                    loading={loading}
                  >
                    Reset password
                  </Button>
                </Form.Item>
              </Form>
              <div className={styles.actions}>
                <a href="/#/register">
                  <Button type="link">
                    Don't have an account yet? Register
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
