/* eslint-disable react-hooks/exhaustive-deps */
import {
  SafetyCertificateFilled,
  SafetyCertificateTwoTone,
} from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { askCode, verifyEmail } from 'services/api/users';
import ProfileContext from 'services/profileContext';
import styles from 'styles/verify.module.scss';

const Verify = () => {
  const location = useLocation();
  const { email } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const [loading, setLoading] = useState(false);
  const [loadingNewCode, setLoadingNewCode] = useState(false);
  const [requestCodeModalOpen, setRequestCodeModalOpen] = useState(false);
  let loadedOnce = false;
  const navigate = useNavigate();
  const { updateProfile } = useContext(ProfileContext);

  const onFinish = (values) => {
    setLoading(true);
    verifyEmail({ email: email, code: values.code })
      .then((res) => {
        message.success('Account verified, redirecting...');
        //store sessionId
        localStorage.setItem('sessionId', res.data.sessionId);
        updateProfile();
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.status === 409) {
          message.error('Your account is already verified, redirecting...');
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        } else if (
          e.response.data.status === 400 &&
          e.response?.data?.error[0]
        ) {
          message.error(
            `${e.response.data.error[0].msg} for ${e.response.data.error[0].path}, please verify it and try again.`
          );
        } else {
          message.error(
            'An error happened, please verify the code and try again'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (code && !loading && !loadedOnce) {
      loadedOnce = true;
      onFinish({ code: code });
    }
  }, []);

  const requestANewCode = () => {
    setLoadingNewCode(true);
    askCode({ email })
      .then(() => {
        message.success('A new code has been sent to your email');
      })
      .catch((e) => {
        console.error(e);
        message.error('Request failed, please try again.');
      })
      .finally(() => {
        setLoadingNewCode(false);
        setRequestCodeModalOpen(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.vignette}>
          <div className={styles.icon}>
            <SafetyCertificateTwoTone twoToneColor={'#08a87f'} />
          </div>
          <h1>Verify your email</h1>
          <p>Your received an email from Karmalinks.io with a code.</p>

          <div className={styles.formWrapper}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={code ? { code: code } : {}}
            >
              <Form.Item
                label={
                  <div>
                    Enter the code received at&nbsp;<b>{email}</b>
                  </div>
                }
                name="code"
                placeholder="Enter the code"
                rules={[
                  {
                    required: true,
                    message: 'Please input the code',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      } else if (value.length < 6) {
                        return Promise.reject(
                          new Error('The code must contain 6 digits')
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  size={'large'}
                  prefix={<SafetyCertificateFilled />}
                  placeholder="Enter the code received"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  size={'large'}
                  type="primary"
                  block
                  loading={loading}
                  htmlType={'submit'}
                >
                  Verify my email
                </Button>
              </Form.Item>
            </Form>
            <div className={styles.cta}>
              <Button
                onClick={() => {
                  setRequestCodeModalOpen(true);
                }}
                type="link"
              >
                I did not receive any email
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        width={420}
        open={requestCodeModalOpen}
        footer={null}
        title="Did you check in the spam folder?"
        onCancel={() => {
          setRequestCodeModalOpen(false);
        }}
      >
        <div>
          <div>
            Emails can fall in the spam folder sometimes.
            <br />
            <br />
            If you still can't find our email, you can try to request a new
            code.
          </div>
          <div style={{ marginTop: 34 }}>
            <div>
              <Button
                type="primary"
                block
                size="large"
                onClick={() => {
                  setRequestCodeModalOpen(false);
                }}
              >
                I found it
              </Button>
            </div>
            <div>
              <Button
                type="link"
                block
                size="large"
                onClick={() => {
                  requestANewCode();
                }}
                loading={loadingNewCode}
              >
                Request a new code
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Verify;
