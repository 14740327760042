/* eslint-disable react-hooks/exhaustive-deps */
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from 'services/api/users';
import styles from 'styles/login.module.scss';
import { isConnected } from 'utils/utils';

const Login = ({ getProfileInfo }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isConnected()) {
      navigate('/dashboard');
    }
  }, []);

  const onFinish = (values) => {
    login({ email: values.email, password: values.password })
      .then(() => {
        return getProfileInfo().finally(() => {
          navigate('/dashboard');
        });
      })
      .catch((e) => {
        console.error(e);
        message.error('Issue when trying to login, please try again.');
      });
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.vignetteWrapper}>
          <div className={styles.vignetteInner}>
            <div>
              <img
                src="/img/logo.png"
                width={130}
                height={130}
                alt="Karmalinks logo"
                className={styles.logo}
              />
            </div>
            <h1>Login to KarmaLinks</h1>
            <div>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="Email"
                  name="email"
                  placeholder="Enter your email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email',
                    },
                  ]}
                >
                  <Input
                    size={'large'}
                    prefix={<UserOutlined />}
                    placeholder="Enter your email"
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password',
                    },
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    prefix={<LockOutlined />}
                    placeholder="Enter your password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size={'large'}
                    type="primary"
                    block
                    htmlType={'submit'}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <div className={styles.actions}>
                <a href="/#/register">
                  <Button type="link">
                    Don't have an account yet? Register
                  </Button>
                </a>
                <a href="/#/forgot-password">
                  <Button type="link">Forgot your password?</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
