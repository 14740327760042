/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { getCategories } from 'services/api/domains';
import ProfileContext from 'services/profileContext';
import styles from 'styles/components/onboarding.module.scss';
const { Form, Button, Select, Input, TreeSelect } = require('antd');

const DomainSettingsForm = ({
  onFinishSettings,
  domainData,
  loadingSettings,
  saveButtonText = 'Save this domain',
  disableAll = false,
  aiMentions = false,
}) => {
  const { profile } = useContext(ProfileContext);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState(null);

  const setSelectables = (categories) => {
    if (!categories || categories.length === 0) {
      return [];
    }
    return categories.reduce(
      (acc, element) => [
        ...acc,
        {
          ...element,
          subCategories: setSelectables(element.subCategories),
          isLeaf: element?.subCategories.length === 0,
          selectable:
            !element.subCategories || element.subCategories.length === 0,
        },
      ],
      []
    );
  };

  const listCategories = () => {
    if (loadingCategories) return;
    setLoadingCategories(true);
    getCategories()
      .then(({ data }) => {
        const selectables = setSelectables(data);
        setCategories(selectables);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoadingCategories(false);
      });
  };

  useEffect(() => {
    if (!categories && !loadingCategories) {
      listCategories();
    }
  }, []);
  return (
    <Form
      layout="vertical"
      onFinish={onFinishSettings}
      initialValues={{
        description: domainData.description,
        language: domainData.language,
        categories: domainData.categories ? domainData.categories[0] : null,
        frequency: domainData.frequency || 1,
      }}
    >
      <Form.Item
        className={styles.formItemSettings}
        label={'How would you describe the website?'}
        name="description"
        placeholder="Enter the Domain description"
        layout="vertical"
        rules={[
          {
            required: true,
            message: 'Please input the domain description',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              } else if (value.length > 100) {
                return Promise.reject(
                  new Error('The description must not exceed 100 characters')
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input.TextArea
          disabled={disableAll}
          size={'large'}
          placeholder="Enter the domain description"
        />
      </Form.Item>
      {aiMentions && (
        <div className={styles.aiPulled}>
          🔮 Data pulled from your website and processed with AI
        </div>
      )}
      <Form.Item
        className={styles.formItemSettings}
        label={"What's the domain's main language?"}
        name="language"
        placeholder="Enter the Domain's main language"
        layout="vertical"
        rules={[
          {
            required: true,
            message: "Please input the domain's main language",
          },
        ]}
      >
        <Select
          showSearch={true}
          disabled={disableAll}
          size="large"
          options={profile.ui.languages.map((l) => ({
            value: l.code,
            label: l.name,
          }))}
        />
      </Form.Item>
      {aiMentions && (
        <div className={styles.aiPulled}>
          🔮 Data pulled from your website and processed with AI
        </div>
      )}
      <Form.Item
        className={styles.formItemSettings}
        label={'Choose the category that best describes the Domain'}
        name="categories"
        placeholder="Enter the Domain's main tags"
        layout="vertical"
        rules={[
          {
            required: true,
            message: "Please input the domain's main category",
          },
        ]}
      >
        <TreeSelect
          disabled={disableAll}
          size="large"
          fieldNames={{
            children: 'subCategories',
            value: '_id',
            label: 'name',
          }}
          showSearch
          treeData={categories}
          filterTreeNode={(input, node) =>
            node.name.toLowerCase().includes(input)
          }
          treeExpandAction={'click'}
        />
      </Form.Item>
      {aiMentions && (
        <div className={styles.aiPulled}>
          🔮 Data pulled from your website and processed with AI
        </div>
      )}
      <Form.Item
        className={styles.formItemSettings}
        label={
          "Choose how often you're ready to receive backlink requests. (this will also define how often you'll receive backlinks from others)"
        }
        name="frequency"
        placeholder="Choose the frequency that suits you best"
        layout="vertical"
        rules={[
          {
            required: true,
            message: 'Please choose a frequency',
          },
        ]}
      >
        <Select
          size="large"
          options={[
            { value: 1, label: 'Every Week' },
            { value: 2, label: 'Every 2 Weeks' },
            { value: 4, label: 'Every Month' },
          ]}
        />
      </Form.Item>
      <Form.Item className={styles.formItemSettings} style={{ marginTop: 24 }}>
        <Button
          size={'large'}
          type="primary"
          loading={loadingSettings}
          htmlType={'submit'}
          block
        >
          {saveButtonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DomainSettingsForm;
