/* eslint-disable react-hooks/exhaustive-deps */
import styles from 'styles/components/onboarding.module.scss';
import { useContext, useEffect, useState } from 'react';
import Welcome from './welcome';
import EnterDomain from './enterDomain';
import VerifyDomain from './verifyDomain';
import DomainContext from 'services/domainContext';
import { LOCAL_STORAGE } from 'utils/constants';
import Loader from 'components/loader';
import VerifyLinkedin from './verifyLinkedin';
import UnderReview from './underReview';
import ProfileContext from 'services/profileContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import Logo from 'components/logo';
import { logout } from 'services/api/users';
import { CloseOutlined } from '@ant-design/icons';

const STEPS = {
  WELCOME: 'WELCOME',
  ENTER_DOMAIN: 'ENTER_DOMAIN',
  VERIFY_DOMAIN: 'VERIFY_DOMAIN',
  LINKEDIN: 'LINKEDIN',
  UNDER_REVIEW: 'UNDER_REVIEW',
};

const Onboarding = ({ firstOnboarding = false, close = () => {} }) => {
  const [currentStep, setCurrentStep] = useState(STEPS.WELCOME);
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const { domains } = useContext(DomainContext);
  const { profile } = useContext(ProfileContext);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const comingFromLinkedinConnect = searchParams.get('linkedin_connect');

  const alreadyHasOneDomainVerified =
    domains.filter((e) => e.verified === true).length > 0;

  const alreadyHasOneDomainApproved =
    domains.filter((e) => e.status !== 'TO_REVIEW').length > 0;

  useEffect(() => {
    // initial load, determine which step suits best
    //only redirect if this is the FIRST Onboarding
    if (!firstLoadDone) {
      setFirstLoadDone(true);
      // const currentDomainValidation = domains.find(
      //   (d) => d.status === 'TO_REVIEW'
      // );
      // if (currentDomainValidation) {
      //   localStorage.setItem(
      //     LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION,
      //     currentDomainValidation.name
      //   );
      // }

      // If coming back from linkedin connect, go to linkedin step
      // https://app.karmalinks.io/#/dashboard?linkedin_connect=true
      if (comingFromLinkedinConnect && comingFromLinkedinConnect === 'true') {
        setCurrentStep(STEPS.LINKEDIN);
      } else if (
        comingFromLinkedinConnect &&
        comingFromLinkedinConnect === 'error'
      ) {
        setCurrentStep(STEPS.LINKEDIN);
        message.error('Linkedin connection failed, please try again.');
      } else {
        const currentDomainName = localStorage.getItem(
          LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION
        );
        if (!currentDomainName || currentDomainName.length === 0) {
          setCurrentStep(
            alreadyHasOneDomainVerified ? STEPS.ENTER_DOMAIN : STEPS.WELCOME
          );
        } else if (
          currentDomainName &&
          currentDomainName.length > 0 &&
          !!domains?.find((d) => d.name === currentDomainName) &&
          !domains.find((d) => d.name === currentDomainName).verified
        ) {
          setCurrentStep(STEPS.VERIFY_DOMAIN);
        } else if (
          currentDomainName &&
          currentDomainName.length > 0 &&
          !!domains?.find((d) => d.name === currentDomainName) &&
          domains.find((d) => d.name === currentDomainName).verified &&
          !profile.linkedinProfile
        ) {
          setCurrentStep(STEPS.LINKEDIN);
        } else if (
          currentDomainName &&
          currentDomainName.length > 0 &&
          !!domains?.find((d) => d.name === currentDomainName) &&
          domains.find((d) => d.name === currentDomainName).verified &&
          profile.linkedinProfile
        ) {
          setCurrentStep(STEPS.UNDER_REVIEW);
        } else if (
          currentDomainName &&
          currentDomainName.length > 0 &&
          !domains?.find((d) => d.name === currentDomainName)
        ) {
          setCurrentStep(STEPS.ENTER_DOMAIN);
        }
      }
    }
  }, []);

  const nextStep = () => {
    if (currentStep === STEPS.UNDER_REVIEW) {
      return;
    }
    const vals = Object.values(STEPS);
    const indexOfCurrentStep = vals.indexOf(currentStep);
    setCurrentStep(STEPS[vals[indexOfCurrentStep + 1]]);
  };

  const previousStep = () => {
    if (currentStep === STEPS.WELCOME) {
      return;
    }
    const vals = Object.values(STEPS);
    const indexOfCurrentStep = vals.indexOf(currentStep);
    setCurrentStep(STEPS[vals[indexOfCurrentStep - 1]]);
  };

  const getStepContent = () => {
    switch (currentStep) {
      default:
      case STEPS.WELCOME:
        return <Welcome nextStep={nextStep} />;
      case STEPS.ENTER_DOMAIN:
        return <EnterDomain nextStep={nextStep} />;
      case STEPS.VERIFY_DOMAIN:
        return <VerifyDomain nextStep={nextStep} previousStep={previousStep} />;
      case STEPS.LINKEDIN:
        return (
          <VerifyLinkedin nextStep={nextStep} previousStep={previousStep} />
        );
      case STEPS.UNDER_REVIEW:
        return <UnderReview close={close} />;
    }
  };
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <div className={styles.vignetteWrapper}>
            {firstLoadDone ? (
              <div className={styles.vignetteInner}>
                <div className={styles.logoWrapper}>
                  <div className={styles.logoInner}>
                    <Logo />
                  </div>
                  <div className={styles.ctaHeader}>
                    {alreadyHasOneDomainApproved ? (
                      <Button
                        type="link"
                        className={styles.logout}
                        icon={<CloseOutlined />}
                        onClick={() => {
                          close();
                        }}
                      >
                        Close
                      </Button>
                    ) : (
                      <Button
                        type="link"
                        className={styles.logout}
                        onClick={() => {
                          logout(() => {
                            navigate('/login');
                          });
                        }}
                      >
                        Logout
                      </Button>
                    )}
                  </div>
                </div>
                {getStepContent()}
              </div>
            ) : (
              <div className={styles.loader}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
