/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from 'styles/components/header.module.scss';
import Logo from './logo';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { logout } from 'services/api/users';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import ProfileContext from 'services/profileContext';

const Header = () => {
  const navigate = useNavigate();
  const { profile } = useContext(ProfileContext);

  const onMenuClick = (e) => {
    logout(() => {
      navigate('/login');
    });
  };

  const items = [
    {
      key: '1',
      label: (
        <span>
          <LogoutOutlined />
          &nbsp;Logout
        </span>
      ),
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <a href="/#/dashboard" style={{ textDecoration: 'none' }}>
            <Logo />
          </a>
        </div>
        <div className={styles.right}>
          <Dropdown.Button
            size="small"
            type="primary"
            menu={{ items, onClick: onMenuClick }}
          >
            <a href="/#/settings">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {profile && profile.linkedinProfile ? (
                  <Avatar size={24} src={profile.linkedinProfile.picture} />
                ) : (
                  <UserOutlined />
                )}
                &nbsp;
                {profile && profile.linkedinProfile
                  ? profile.linkedinProfile.name
                  : profile.email}
              </div>
            </a>
          </Dropdown.Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
