/* eslint-disable react-hooks/exhaustive-deps */
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './styles/App.module.scss';
import Register from './pages/register';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/resetPassword';
import FullScreenLoader from './components/loader';
import { useEffect, useState } from 'react';
import { getProfile, logout } from 'services/api/users';
import { isConnected, isLocalhost } from 'utils/utils';
import Login from 'pages/login';
import Verify from 'pages/verify';
import Dashboard from 'pages/dashboard';
import ProfileContext from 'services/profileContext';
import DomainContext from 'services/domainContext';
import { getAllDomains } from 'services/api/domains';
import Settings from 'pages/settings';
import posthog from 'posthog-js';
function App() {
  const [loading, setLoading] = useState(false);
  const getUserProfile = () => {
    if (loading) return;
    setLoading(true);
    return getProfile()
      .then(({ data }) => {
        if (!isLocalhost()) {
          if (!profile?.profile) {
            posthog.init('phc_oZTNqgzO6PqKH8fI9y86SnXDhEHpGI5J9lqcqv0aVVk', {
              api_host: 'https://eu.posthog.com',
            });
          }
          posthog.identify(data._id, {
            name: data.name,
            email: data.email,
          });
        }
        setProfile({ profile: data, updateProfile: getUserProfile });
        return listDomains();
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          logout(() => {
            window.location.replace(`${window.location.origin}/login`);
          });
        } else {
          console.error(err);
        }
      })
      .finally(() => {
        setLoading(false);
        setFirstLoadDone(true);
      });
  };

  const listDomains = () =>
    getAllDomains()
      .then((res) => {
        setDomains({ domains: res.data, updateDomains: listDomains });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          logout(() => {
            window.location.replace(`${window.location.origin}/login`);
          });
        } else {
          console.error(err);
        }
      })
      .finally(() => setLoading(false));

  const [profile, setProfile] = useState({
    profile: null,
    updateProfile: getUserProfile,
  });
  const [domains, setDomains] = useState({
    domains: null,
    updateDomains: listDomains,
  });

  const [firstLoadDone, setFirstLoadDone] = useState(false);

  useEffect(() => {
    if (isConnected()) {
      getUserProfile();
    } else {
      setFirstLoadDone(true);
    }
  }, []);

  if (!firstLoadDone) {
    return <></>;
  }

  return (
    <div className="App">
      <DomainContext.Provider value={domains}>
        <ProfileContext.Provider value={profile}>
          <HashRouter>
            {firstLoadDone && (
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route
                  path="/login"
                  element={<Login getProfileInfo={getUserProfile} />}
                />
                <Route
                  path="/forgot-password"
                  exact
                  element={<ForgotPassword />}
                />
                <Route
                  path="/reset-password/:resetPasswordToken"
                  element={<ResetPassword />}
                />
                <Route path="/verify/:email" element={<Verify />} />
                {isConnected() && profile ? (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                  </>
                ) : (
                  <Route path="/" element={<Navigate to="/login" replace />} />
                )}

                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            )}
          </HashRouter>
        </ProfileContext.Provider>
      </DomainContext.Provider>

      {loading && <FullScreenLoader />}
    </div>
  );
}

export default App;
