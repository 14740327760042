import {
  CalendarTwoTone,
  // EyeTwoTone,
  SafetyCertificateTwoTone,
} from '@ant-design/icons';
import { Button, Divider, Modal } from 'antd';
import { useContext, useState } from 'react';
import DomainContext from 'services/domainContext';
import styles from 'styles/components/onboarding.module.scss';

const UnderReview = ({ close }) => {
  const [whiteListModalOpen, setWhiteListModalOpen] = useState(false);
  const openModalWhitelist = () => {
    setWhiteListModalOpen(true);
  };

  const goToDashboard = () => {
    close();
  };

  const domains = useContext(DomainContext);
  const hasOneValidatedDomain =
    domains?.domains?.filter((a) => a.status === 'APPROVED')?.length > 0;

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20with%20Face.png"
          alt="Sun with Face"
          width="150"
          height="150"
        />
      </div>
      <h1 className={styles.title}>
        {hasOneValidatedDomain
          ? 'Your new domain is under review'
          : 'Your account is under review'}
      </h1>
      <div>
        {hasOneValidatedDomain
          ? "We’re reviewing your domain and will let you know once it's accepted."
          : 'We’re reviewing your account and will let you know once you’re accepted.'}

        <br />
      </div>
      <Divider />
      <h2 className={styles.whatNextTitle}>What to do next?</h2>
      <div className={styles.nextStepWrapper}>
        <div className={styles.nextStepElement}>
          <div className={styles.nextStepElementLeft}>
            <SafetyCertificateTwoTone
              className={styles.nextStepIcon}
              twoToneColor={'#08a87f'}
            />
          </div>
          <div className={styles.nextStepElementRight}>
            <h3>Whitelist Karmalink email</h3>
            <p>Ensuring you’ll receive our emails with backlink requests</p>
            <div className={styles.nextStepAction}>
              <Button
                onClick={openModalWhitelist}
                type="link"
                style={{ paddingLeft: 0 }}
              >
                How to whitelist Karmalink
              </Button>
            </div>
          </div>
        </div>
        {/* <div className={styles.nextStepElement}>
          <div className={styles.nextStepElementLeft}>
            <EyeTwoTone
              className={styles.nextStepIcon}
              twoToneColor={'#08a87f'}
            />
          </div>
          <div className={styles.nextStepElementRight}>
            <h3>Read the code of conduct</h3>
            <p>Full of best practises and rules for backlinks</p>
            <div className={styles.nextStepAction}>
              <a href="/" title="">
                <Button type="link" style={{ paddingLeft: 0 }}>
                  Code of conduct
                </Button>
              </a>
            </div>
          </div>
        </div> */}
        <div className={styles.nextStepElement}>
          <div className={styles.nextStepElementLeft}>
            <CalendarTwoTone
              className={styles.nextStepIcon}
              twoToneColor={'#08a87f'}
            />
          </div>
          <div className={styles.nextStepElementRight}>
            <h3>Add this event to your calendar</h3>
            <p>
              Note that you’ll receive backlink requests on Mondays - 10am EST
            </p>
            <div className={styles.nextStepAction}>
              <a
                href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=Backlink from Karmalinks.io&details=Find the new Backlink on https://app.karmalinks.io/#/dashboard&dates=20241202T100000/20241202T103000&recur=RRULE:FREQ%3DWEEKLY;UNTIL%3D20280603&ctz=America/Toronto"
                title="Add karmalinks to google calendar"
                target="_blank"
                rel="noreferrer"
              >
                <Button type="link" style={{ paddingLeft: 0 }}>
                  Add to Google Calendar
                </Button>
              </a>
            </div>
          </div>
        </div>
        {hasOneValidatedDomain && (
          <div style={{ textAlign: 'center', marginTop: 26 }}>
            <Button
              type="primary"
              onClick={goToDashboard}
              size="large"
              style={{ minWidth: 300 }}
            >
              Go to Dashboard
            </Button>
          </div>
        )}
      </div>
      <Modal
        open={whiteListModalOpen}
        onCancel={() => {
          setWhiteListModalOpen(false);
        }}
        okText={'Close'}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setWhiteListModalOpen(false);
            }}
          >
            Close
          </Button>
        }
        onOk={() => {
          setWhiteListModalOpen(false);
        }}
        title={'How to whitelist Karmalinks.io email'}
      >
        Follow these steps if you're using Gmail/Google Workspace:
        <ul>
          <li>
            Click the gear icon in Gmail and select "See all settings" from the
            Quick settings menu.
            <div>
              <img src="/img/whitelist.png" alt="whitelist email" />
            </div>
          </li>
          <li>Navigate to "Filters and Blocked Addresses" in the top menu.</li>
          <li>Select "Create a new filter."</li>
          <li>Add a specific email or a whole domain in the "From" field.</li>
          <li>Click "Create filter."</li>
          <li>Whitelist backlinkrequest@karmalinks.io</li>
        </ul>
      </Modal>
    </div>
  );
};
export default UnderReview;
