import { useContext, useState } from 'react';
import DomainSettingsForm from './domainSettingsForm';
import styles from 'styles/components/domainSettingsModal.module.scss';
import DomainContext from 'services/domainContext';
import { pauseDomain, resumeDomain, updateDomain } from 'services/api/domains';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

const { Modal, Button, message, Form, Switch, Radio, Space } = require('antd');

const DomainSettingsModal = ({
  domain,
  isOpen = false,
  onClose = () => {},
  getDomainDetails = () => {},
}) => {
  const { updateDomains } = useContext(DomainContext);
  const [loadingPause, setLoadingPause] = useState(false);
  const [pauseDomainModalOpen, setPauseDomainModalOpen] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const pauseDomainFunction = (values) => {
    setLoadingPause(true);
    pauseDomain({
      name: domain.name,
      reason: values.reason,
    })
      .then(() => {
        message.success(`${domain.name} was paused successfully`);
        closePauseDomainModal();
        updateDomains();
        return getDomainDetails();
      })
      .catch((e) => {
        console.error(e);
        message.error(
          'There was a problem when trying to pause the domain, please refresh your page an try again.'
        );
      })
      .finally(() => {
        setLoadingPause(false);
      });
  };
  const resumeDomainFunction = () => {
    setLoadingPause(true);
    resumeDomain({
      name: domain.name,
    })
      .then(() => {
        message.success(`${domain.name} was resumed successfully`);
        updateDomains();
        return getDomainDetails();
      })
      .catch((e) => {
        console.error(e);
        message.error(
          'There was a problem when trying to resume the domain, please refresh your page an try again.'
        );
      })
      .finally(() => {
        setLoadingPause(false);
      });
  };

  const openPauseDomainModal = () => setPauseDomainModalOpen(true);
  const closePauseDomainModal = () => setPauseDomainModalOpen(false);

  const onChangePause = (checked) => {
    if (checked) {
      resumeDomainFunction();
    } else {
      openPauseDomainModal();
    }
  };

  const updateSettings = (values) => {
    setLoadingUpdate(true);
    updateDomain({ frequency: values.frequency, name: domain.name })
      .then(() => {
        onClose();
        updateDomains();
        message.success('Domain settings updated successfully');
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while saving, try again.');
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  return (
    <>
      <Modal
        footer={null}
        title={`Settings${domain ? ` for ${domain.name}` : ''}`}
        onCancel={onClose}
        open={isOpen}
        className={styles.modal}
      >
        <div className={styles.pauseWrapper}>
          <label>
            <PauseCircleOutlined />
            &nbsp;Domain Paused
          </label>
          <Switch
            loading={loadingPause}
            checked={domain.live}
            onChange={onChangePause}
            className={styles.switchPause}
            size="large"
          />
          <label>
            <PlayCircleOutlined />
            &nbsp;Domain Live
          </label>
          <div className={styles.hint}>
            When the domain is live, you'll receive backlink requests regularly
            as well as backlinks to your domain.
          </div>
        </div>

        <DomainSettingsForm
          saveButtonText="Save"
          loadingSettings={loadingUpdate}
          domainData={domain}
          onFinishSettings={updateSettings}
          disableAll={true}
        />
      </Modal>
      {pauseDomainModalOpen && (
        <Modal
          title={`Pause ${domain.name}`}
          footer={null}
          open={pauseDomainModalOpen}
          onCancel={closePauseDomainModal}
        >
          <div>
            Setting your account as Paused will stop the backlinks proposals and
            you won’t receive backlinks
          </div>
          <br />
          <Form onFinish={pauseDomainFunction} layout="vertical">
            <Form.Item
              label={
                'Enter a reason for pausing (it helps us improve the platform)'
              }
              name="reason"
              placeholder="Enter a reason for pausing the domain"
              layout="vertical"
              rules={[
                {
                  required: true,
                  message: 'Please enter a reason for pausing',
                },
              ]}
            >
              <Radio.Group size="large" direction="vertical">
                <Space direction="vertical">
                  <Radio
                    value={'Low quality of backlinks to add to my website'}
                  >
                    Low quality of backlinks to add to my website
                  </Radio>
                  <Radio value={'Low quality of backlinks received'}>
                    Low quality of backlinks received
                  </Radio>
                  <Radio value={'Not enough time to add the backlinks'}>
                    Not enough time to add the backlinks
                  </Radio>
                  <Radio value={'I have enough backlinks'}>
                    I have enough backlinks
                  </Radio>
                  <Radio value={'other'}>Other</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button
                size={'large'}
                type="primary"
                loading={loadingPause}
                htmlType={'submit'}
                block
              >
                Pause the domain
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default DomainSettingsModal;
