import {
  CheckCircleFilled,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, message, Rate, Tag } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { register } from 'services/api/users';
import styles from 'styles/login.module.scss';

const Argument = ({ content }) => (
  <div className={styles.argumentWrapper}>
    <div>
      <CheckCircleFilled className={styles.argumentIcon} />
    </div>
    <div className={styles.argumentContent}>{content}</div>
  </div>
);

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refParam = searchParams.get('ref');

  const onFinish = (values) => {
    setLoading(true);
    register({
      email: values.email,
      password: values.password,
      ref: refParam && refParam.length > 0 ? refParam : undefined,
    })
      .then(() => {
        // go to verify-email
        navigate(`/verify/${values.email}`);
      })
      .catch((e) => {
        console.error(e);
        message.error('Error during registration, please try again');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.registerWrapper}>
          <div className={styles.registerLeft}>
            <div>
              <Tag color="blue">BEST BACKLINK PLAFTORM</Tag>
            </div>
            <h1>KarmaLinks - The #1 Backlink exchange platform</h1>
            <div className={styles.rate}>
              <Rate defaultValue={5} disabled />
              <div className={styles.rateText}>4.9/5 (128)</div>
            </div>
            <div className={styles.argumentList}>
              <Argument
                content={
                  'Backlink proposals in your inbox on Mondays - 10am EST (choose weekly, bi-weekly or monthly)'
                }
              />
              <Argument
                content={
                  'Save +100s of hours - No need to reach out to people asking for backlinks anymore'
                }
              />
              <Argument
                content={'High quality domains, no spam, strong verification'}
              />
              <Argument
                content={
                  'AI suggests the best matching and soon will write anchor and content to help you'
                }
              />
              <Argument
                content={
                  'Publish requested backlinks to keep your Karma score up'
                }
              />
            </div>
          </div>
          <div className={styles.vignetteWrapper}>
            <div className={styles.vignetteInner}>
              <div>
                <img
                  src="/img/logo.png"
                  width={130}
                  height={130}
                  alt="Karmalinks logo"
                  className={styles.logo}
                />
              </div>
              <h1>Register to KarmaLinks</h1>
              <div>
                <Form layout="vertical" onFinish={onFinish}>
                  <Form.Item
                    label="Enter your Business Email"
                    name="email"
                    placeholder="Enter your email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                    ]}
                  >
                    <Input
                      size={'large'}
                      prefix={<UserOutlined />}
                      placeholder="Enter your email"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Enter a Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          } else if (value.length < 8) {
                            return Promise.reject(
                              new Error(
                                'The password must contain more than 8 caracters'
                              )
                            );
                          } else if (!/[A-Z]+/.test(value)) {
                            return Promise.reject(
                              new Error(
                                'The password must contain at least 1 capital letter'
                              )
                            );
                          } else if (!/[a-z]/.test(value)) {
                            return Promise.reject(
                              new Error(
                                'The password must contain at least 1 lowercase letter'
                              )
                            );
                          } else if (!/\d/.test(value)) {
                            return Promise.reject(
                              new Error(
                                'The password must contain at least 1 number'
                              )
                            );
                          } else if (!/[^\w\s]/.test(value)) {
                            return Promise.reject(
                              new Error(
                                'The password must contain at least 1 symbol'
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size={'large'}
                      prefix={<LockOutlined />}
                      placeholder="Enter your password"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm the password"
                    name="confirm"
                    rules={[
                      {
                        required: true,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The new password that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size={'large'}
                      prefix={<LockOutlined />}
                      placeholder="Enter your password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size={'large'}
                      type="primary"
                      block
                      loading={loading}
                      htmlType={'submit'}
                    >
                      Create my account
                    </Button>
                  </Form.Item>
                </Form>
                <div className={styles.actions}>
                  <a href="/#/login">
                    <Button type="link">Already have an account? Login.</Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
