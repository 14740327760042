/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleFilled,
  LinkOutlined,
  MailOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, message, Radio, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import DomainContext from 'services/domainContext';
import { LOCAL_STORAGE } from 'utils/constants';
import styles from 'styles/components/onboarding.module.scss';
import {
  deleteUnreviewedBacklink,
  getCodeForDomain,
  verifyBacklink,
  verifyByEmailCode,
} from 'services/api/domains';
import Loader from 'components/loader';

const VerifyDomain = ({ nextStep, previousStep }) => {
  const { domains, updateDomains } = useContext(DomainContext);
  const [methodSelected, setMethodSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingValidateCode, setLoadingValidateCode] = useState(false);
  const [loadingVerifyBacklink, setLoadingVerifyBacklink] = useState(false);
  const [disabledAskCode, setDisabledAskCode] = useState(false);
  const [loadingDeleteDomain, setLoadingDeleteDomain] = useState(false);
  const [currentDomain, setCurrentDomain] = useState({});
  const currentDomainName = localStorage.getItem(
    LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION
  );
  useEffect(() => {
    const currentDomainFound = domains.find(
      (d) => d.name === currentDomainName
    );
    setCurrentDomain(currentDomainFound);
  }, [domains]);

  const handleMethodChange = ({ target }) => {
    setMethodSelected(target.value);
  };

  const onFinishEnteringEmail = (values) => {
    const emailToUse = `${values.email}@${currentDomain.name}`;
    setLoading(true);
    getCodeForDomain({ email: emailToUse, name: currentDomain.name })
      .then((res) => {
        message.success(
          `Check your emails on ${emailToUse}, a code has been sent to you.`
        );
        setDisabledAskCode(true);
        setTimeout(() => {
          setDisabledAskCode(false);
        }, 60000);
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.error === 'DOMAIN_ALREADY_VERIFIED') {
          message.error(
            'This domain is already verified. Try refreshing this page.'
          );
        } else if (e.response.data.error === 'ASKED_MORE_THAN_5_TIMES') {
          // ASKED_MORE_THAN_5_TIMES
          message.error(
            'The code has been asked more than 5 times. Contact support.'
          );
        } else if (e.response.data.error === 'WAIT_AT_LEAST_1_MINUTE') {
          // ASKED_MORE_THAN_5_TIMES
          message.error('Wait at least 1 minute before asking another code.');
        } else {
          message.error('Error while asking code. Please try again.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishEnteringCode = (values) => {
    setLoadingValidateCode(true);
    verifyByEmailCode({ code: values.code, name: currentDomain.name })
      .then(() => {
        message.success(`Validation successful`);
        updateDomains();
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while validating code. Please try again.');
      })
      .finally(() => {
        setLoadingValidateCode(false);
      });
  };

  const onFinishBacklink = (values) => {
    try {
      new URL(values.url);
    } catch (e) {
      message.error(
        'This is not a valid URL, please enter a valid link (starting with https://).'
      );
      return;
    }
    setLoadingVerifyBacklink(true);
    verifyBacklink({ name: currentDomain.name, url: values.url })
      .then(() => {
        message.success(`Validation successful`);
        updateDomains();
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.error === 'INVALID_URL_DOMAIN') {
          message.error(`This domain is not ${currentDomain.name}`);
        } else if (e.response.data.error === 'CANNOT_SCRAP_URL') {
          message.error(
            `The URL could not be verified, is the link accessible?`
          );
        } else if (e.response.data.error === 'LINK_NOT_FOUND') {
          message.error(
            `The backlink was not found, verify that it's available and try again.`
          );
        } else {
          message.error('Error while validating backlink. Please try again.');
        }
      })
      .finally(() => {
        setLoadingVerifyBacklink(false);
      });
  };

  const deleteAndBack = () => {
    setLoadingDeleteDomain(true);
    deleteUnreviewedBacklink({
      name: currentDomain.name,
    })
      .then(() => {
        updateDomains();
        localStorage.removeItem(LOCAL_STORAGE.CURRENT_DOMAIN_VALIDATION);
        previousStep();
      })
      .catch((e) => {
        console.error(e);
        message.error('Error while trying to go back');
      })
      .finally(() => {
        setLoadingDeleteDomain(false);
      });
  };

  return currentDomain?.verified ? (
    <div>
      <div className={styles.verifyIcon}>
        <CheckCircleFilled />
      </div>
      <h1 className={styles.title}>
        <span style={{ color: 'white' }}>{currentDomain?.name}</span>
        {` is verified`}
      </h1>
      <div className={styles.cta}>
        <Button
          type="primary"
          size="large"
          onClick={nextStep}
          className={styles.nextButton}
        >
          Next step
        </Button>
      </div>
    </div>
  ) : currentDomain ? (
    <div>
      <h1>{`Verify ${currentDomain?.name}`}</h1>
      <div>
        You need to verify that the domain is yours. You have 2 methods to
        verify the domain:
      </div>
      <div style={{ marginTop: 12, display: 'flex', justifyContent: 'center' }}>
        <Radio.Group onChange={handleMethodChange} buttonStyle="solid">
          <Radio.Button value="email">{`Validate by email`}</Radio.Button>
          <Radio.Button value="backlink">{`Add a backlink on ${currentDomain.name}`}</Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.useAnother}>
        <Button
          type="link"
          loading={loadingDeleteDomain}
          onClick={deleteAndBack}
        >
          Use another domain
        </Button>
      </div>
      {methodSelected && (
        <div>
          {methodSelected === 'email' && (
            <div>
              <div
                style={{ marginTop: 34, marginBottom: 14, fontWeight: 'bold' }}
              >
                We'll send a code to an email belonging to the domain{' '}
                {currentDomain.name}.
              </div>
              <Form layout="inline" onFinish={onFinishEnteringEmail}>
                <Form.Item
                  label={'Enter an email to receive a validation code'}
                  name="email"
                  placeholder="Enter an email"
                  layout="vertical"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the domain URL',
                    },
                  ]}
                >
                  <Input
                    size={'large'}
                    prefix={<MailOutlined />}
                    placeholder="Enter an email"
                    suffix={`@${currentDomain.name}`}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    flexGrow: 1,
                    flex: 1,
                  }}
                >
                  <Button
                    size={'large'}
                    type="primary"
                    loading={loading}
                    htmlType={'submit'}
                    style={{
                      marginTop: 28,
                    }}
                    block
                    disabled={disabledAskCode}
                  >
                    Receive code
                  </Button>
                </Form.Item>
              </Form>
              <Form
                layout="inline"
                style={{ marginTop: 16 }}
                onFinish={onFinishEnteringCode}
              >
                <Form.Item
                  label={'Enter the code received by email'}
                  name="code"
                  placeholder="Enter the code received"
                  layout="vertical"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the code received by email',
                    },
                  ]}
                >
                  <Input
                    size={'large'}
                    prefix={<SafetyCertificateOutlined />}
                    placeholder="Enter the code"
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    marginTop: 28,
                    flexGrow: 1,
                    flex: 1,
                  }}
                >
                  <Button
                    size={'large'}
                    type="primary"
                    loading={loadingValidateCode}
                    htmlType={'submit'}
                    block
                  >
                    Verify code
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          {methodSelected === 'backlink' && (
            <div>
              <div>
                <h3>Instructions to verify the domain with a backlink</h3>
                <ul className={styles.instructionsBacklink}>
                  <li>
                    The goal is to add a backlink from{' '}
                    <b>{currentDomain.name}</b> to&nbsp;
                    <b>Karmalinks.io</b>
                  </li>
                  <li>Domain source: {currentDomain.name}</li>
                  <li>Choose any page as long as it's on the given domain</li>
                  <li>
                    Backlink:{' '}
                    <Typography.Text copyable code>
                      {`https://karmalinks.io/?verificationId=${currentDomain._id}`}
                    </Typography.Text>
                  </li>
                </ul>
                <Form
                  layout="inline"
                  style={{ marginTop: 16 }}
                  onFinish={onFinishBacklink}
                >
                  <Form.Item
                    label={'Enter the URL where the backlink was placed'}
                    name="url"
                    placeholder="Enter the URL where the backlink was placed"
                    layout="vertical"
                    rules={[
                      {
                        required: true,
                        message:
                          'Please input the URL where the backlink was placed',
                      },
                    ]}
                  >
                    <Input
                      size={'large'}
                      prefix={<LinkOutlined />}
                      placeholder="Enter the URL of the backlink"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginTop: 28,
                      flexGrow: 1,
                      flex: 1,
                    }}
                  >
                    <Button
                      size={'large'}
                      type="primary"
                      loading={loadingVerifyBacklink}
                      htmlType={'submit'}
                      block
                    >
                      Verify backlink
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default VerifyDomain;
