import { Button, Form, Input, message, Modal, Table } from 'antd';
import { BACKLINK_REQUEST_STATUS } from 'utils/constants';
import styles from 'styles/dashboard.module.scss';
import moment from 'moment';
import { LinkOutlined } from '@ant-design/icons';
import DomainContext from 'services/domainContext';
const { useState, useContext } = require('react');
const { submitBacklink, fixBacklink } = require('services/api/domains');

const SentBacklinksList = ({ domain, getDomainDetails }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(null);
  const { updateDomains } = useContext(DomainContext);

  const dataSource = domain.backlinks.sent.map((s) => ({ ...s, key: s._id }));

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      render: (s, el) => `${el.to.name}`,
    },
    {
      title: 'Points you earn',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (element) => `${moment(element).format('Do MMM YYYY - HH:mm')}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (element, obj) => {
        switch (element) {
          default:
          case BACKLINK_REQUEST_STATUS.PENDING:
            return (
              <div
                className={styles.status}
                style={{
                  color: '#a064ff',
                }}
              >
                {element}
              </div>
            );
          case BACKLINK_REQUEST_STATUS.LIVE:
            return (
              <div
                className={styles.status}
                style={{
                  color: '#08ca11',
                }}
              >
                {element}
              </div>
            );
          case BACKLINK_REQUEST_STATUS.WARNING:
            return (
              <div
                className={styles.status}
                style={{
                  color: '#e98b0c',
                }}
              >
                {element}
              </div>
            );
          case BACKLINK_REQUEST_STATUS.LOST:
            return (
              <div
                className={styles.status}
                style={{
                  color: '#ea0a0a',
                }}
              >
                {element}
              </div>
            );
          case BACKLINK_REQUEST_STATUS.EXPIRED:
            return (
              <div
                className={styles.status}
                style={{
                  color: '#7f7f7f',
                }}
              >
                {element}
              </div>
            );
        }
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (element, obj) => {
        if (obj.status === BACKLINK_REQUEST_STATUS.PENDING) {
          return (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setSubmitModalOpen(obj._id);
              }}
            >
              Submit Backlink
            </Button>
          );
        } else if (obj.status === BACKLINK_REQUEST_STATUS.LOST) {
          return (
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setSubmitModalOpen(obj._id);
              }}
            >
              Resubmit Backlink
            </Button>
          );
        }
        return <></>;
      },
    },
  ];

  const submitBacklinkFunction = (values) => {
    setLoadingSubmit(true);
    const currentD = domain.backlinks.sent.find(
      (e) => e._id === submitModalOpen
    );
    const functionToCall =
      currentD.status === BACKLINK_REQUEST_STATUS.LOST
        ? fixBacklink
        : submitBacklink;
    functionToCall({
      requestId: submitModalOpen,
      url: values.url,
    })
      .then(() => {
        message.success('Backlink submitted successsfully');
        setSubmitModalOpen(false);
        getDomainDetails();
        updateDomains();
      })
      .catch((e) => {
        console.error(e);
        if (e.response.data.error === 'LINK_NOT_FOUND') {
          message.error('Link was not found, verify the link and try again.');
        } else if (e.response.data.error === 'URL_MISMATCH_DOMAIN_NAME') {
          message.error(
            'The domain is not correct. Try again or contact support.'
          );
        } else {
          message.error(
            'Error while submitting backlink. Try again or contact support.'
          );
        }
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  return (
    <div>
      <Table
        locale={{ emptyText: 'No Backlinks yet' }}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Modal
        title="Submit a backlink"
        onCancel={() => setSubmitModalOpen(false)}
        open={!!submitModalOpen}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={submitBacklinkFunction}
          style={{ marginTop: 28 }}
        >
          <Form.Item
            label={'Enter the backlink URL'}
            name="url"
            placeholder="Enter a backlink URL"
            layout="vertical"
            rules={[
              {
                required: true,
                message: 'Please input the backlink URL',
              },
            ]}
          >
            <Input
              size={'large'}
              prefix={<LinkOutlined />}
              placeholder="Enter an URL"
            />
          </Form.Item>
          <Form.Item>
            <Button
              size={'large'}
              type="primary"
              loading={loadingSubmit}
              htmlType={'submit'}
              block
            >
              Submit Backlink
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SentBacklinksList;
