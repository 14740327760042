import styles from 'styles/components/logo.module.scss';

const Logo = ({ withPicture = true, withText = true }) => (
  <div className={styles.logo}>
    {withPicture && (
      <div className={styles.iconWrapper}>
        <img
          className={styles.icon}
          src="/img/logo.png"
          alt="logo karmalinks"
        />
      </div>
    )}
    {withText && <div className={styles.text}>KarmaLinks.io</div>}
  </div>
);

export default Logo;
