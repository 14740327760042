import styles from 'styles/components/onboarding.module.scss';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const Welcome = ({ nextStep }) => {
  return (
    <>
      <div className={styles.icon}>
        <img src="/img/wavinghand.png" alt="welcome to karmalinks" />
      </div>
      <h1 className={styles.title}>Welcome to KarmaLinks</h1>
      <div className={styles.subtitle}>
        Before you start, here are a few information about the platform
      </div>
      <div className={styles.arguments}>
        <ul>
          <li>
            <CheckCircleFilled className={styles.argumentIcon} />
            Karmalinks is a backlink exchange platform.
          </li>
          <li>
            <CheckCircleFilled className={styles.argumentIcon} />
            Contribute to get Karma points (number of points equals your DR
            number).
          </li>
          <li>
            <CheckCircleFilled className={styles.argumentIcon} />
            These points are used to get backlinks for yourself.
          </li>
          <li>
            <CheckCircleFilled className={styles.argumentIcon} />
            You stay anonymous and only receive backlinks from verified domains
            having a Domain Rating close to yours.
          </li>
        </ul>
      </div>
      <div className={styles.cta}>
        <Button
          style={{ minWidth: 300 }}
          size="large"
          type="primary"
          onClick={() => {
            nextStep();
          }}
        >
          I understand
        </Button>
      </div>
    </>
  );
};

export default Welcome;
